#root {
  overflow: hidden;
}

.logo {
  margin-top: 50px;
}

.logo img {
  width: 250px;
  margin: auto;
}

.logoInner {
  display: flex;
  justify-content: center;
}

.firstSection {
  background-color: #e40421;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px 0;
}

.firstLine {
  color: white;
  font-size: 3rem;
}

.firstLineQuery {
  display: none;
}

.secondLine {
  font-size: 2.2rem;
  margin-top: 40px;
  margin-bottom: 20px;
  /* font-family:Georgia, 'Times New Roman', Times, serif; */
  color: red;
  font-weight: bold;
}

.secondSection {
  height: 210px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: -10px;
}

.whiteSection {
  /* background-color: white;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin-top: 20px;
  color: black; */
  margin: auto;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  /* position: inherit; */
  /* height: inherit; */
  /* margin-top: -375px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 250px; */
}

.whiteSectionBottom {
  margin-bottom: 40px;
}

.secondFirst,
.secondSecond {
  font-size: 1.8rem;
}

.secondFirst {
  font-weight: bold;
}

.secondSecond {
  margin-top: 10px;
}

.secondSection {
  /* margin-top: 20px; */
}

.mainSection {
  overflow: hidden;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mainMiddle {
  text-align: center;
  padding: 70px 30px 60px 30px;
  font-size: 1.5rem;
}

.mainMiddle iframe {
  margin-top: 30px;
}

.firstLinePremier {
  margin-bottom: -4px;
}

.middleTxt {
  text-align: left;
}

.middleTxt-2 {
  margin-top: 20px;
}

.middleTxtList {
  text-align: left;
  max-width: fit-content;
  margin: auto;
  margin-top: 20px;
}

.videoSub {
  font-size: .9rem;
  margin-bottom: 30px;
}

.mainRight {
  background-image: url('../src/images/circle-right.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.mainLeft {
  background-image: url('../src/images/circle-left.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}

.mainQuery {
  display: none;
}

.hourSectionQuery {
  display: none;
}

.itemQuery {
  margin-bottom: .5rem;
}

.bottomSection {
  background-color: #CBC8C9;
  padding: 30px 0;
}

.bottomSection h3 {
  margin-top: 20px;
  font-weight: bold;
}

.bottomSection div {
  font-size: 1.2rem;
  line-height: 130%;
}

.bottomSection a {
  color: red;
  font-weight: bold;
}

.bottomQuery {
  display: none;
}

.formSubTitle {
  font-size: .9rem !important;
  font-weight: bold;
}

.formTitle {
  margin-top: 0;
}

.contactForm {
  text-align: left;
  font-family: 'Quicksand', sans-serif;
}

.contactForm input,
textarea {
  background-color: var(--lightGray);
  border: none;
  width: 100%;
}

.contactForm input {
  height: 50px;
  padding-left: 20px;
}

.formInput {
  margin: 20px auto;
}

.formInput input {
  background-color: white;
}

.formInput ::placeholder {
  font-size: .7rem;
  color: rgb(151, 151, 151);
}

.contactUsWrapper {
  margin-bottom: 50px;
}

.formButton input {
  background-color: #e40421;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}

.showMessage {
  display: block;
}

.hideMessage {
  display: none;
}

.necessaryMsg {
  font-size: .8rem !important;
  text-align: right;
  margin-bottom: 30px;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

footer img {
  width: 60px;
}

footer h3 {
  font-weight: bold;
  font-size: 2.1rem;
  margin-bottom: 20px;
}

.footerTxtQuery {
  display: none;
}

.footerTxt {
  margin-top: 30px;
}

@media only screen and (max-width:2506px) {
  .videoContainer {
    /* height: 475px; */
    height: auto;
  }
}

@media only screen and (max-width:1880px) {
  .videoContainer {
    /* max-height: 352px; */
    height: auto;
  }
}

@media only screen and (max-width:1250px) {
  iframe {
    width: 600px;
    height: 337px;
  }

  .logo img {
    width: 187px;
  }
}

@media only screen and (max-width:912px) {
  .mainSection {
    padding: 0 30px;
  }
}

@media only screen and (max-width:980px) {
  .mainFirst {
    display: none;
  }

  .mainQuery {
    display: block;
  }

  .bottomQuery {
    display: block;
  }

  .bottomFirst {
    display: none;
  }

  .textOfBottomSection,
  .formContainer {
    width: 60vw;
    margin: auto;
    text-align: center;
  }

  .bottomSection h3 {
    margin-top: 40px;
  }

  .bottomFirstLine {
    margin-top: 0 !important;
  }

  .whiteSection div {
    padding: auto 50px;
  }

  .firstSection {
    padding: 45px;
  }
}

@media only screen and (max-width:940px) {
  .secondSecond {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width:880px) {
  .firstLine {
    font-size: 2.1rem;
  }
}

@media only screen and (max-width:820px) {
  .formContainer {
    width: 80vw;
  }
}

@media only screen and (max-width:760px) {
  .middleTxt {
    width: 80vw;
    margin: auto;
  }

  footer h3 {
    font-size: 1.8rem;
  }

  .middleTxt-2 {
    margin-top: 20px;
  }
}

@media only screen and (max-width:700px) {
  .secondSecond {
    width: 70vw;
    margin-left: auto;
    margin-right: auto
  }
}

@media only screen and (max-width:640px) {
  footer h3 {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width:620px) {
  .mainMiddle iframe {
    width: 96vw;
    height: 54vw;
  }
}

@media only screen and (max-width:590px) {
  .firstLine {
    font-size: 1.8rem;
  }

  .secondLine {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width:520px) {
  .secondFirst {
    font-size: 1.2rem;
  }

  .secondSecond {
    font-size: 1rem;
  }

  .textOfBottomSection h3,
  .formContainer h3 {
    font-size: 1.3rem;
  }

  .textOfBottomSection div {
    font-size: 1rem;
  }

  .secondSection {
    height: 153px;
  }
}

@media only screen and (max-width:495px) {
  footer h3 {
    width: 80vw;
    text-align: center;
  }
}

@media only screen and (max-width:469px) {
  .style_error__O0vWC {
    font-size: .8rem !important;
  }

  .hourSection {
    display: none;
  }

  .hourSectionQuery {
    display: block;
  }

  .footerTxtQuery {
    display: block;
  }

  .footerTxt {
    display: none;
  }

  .firstLineQuery {
    display: block;
  }

  .firstLineQuery div {
    margin-bottom: -10px;
  }

  .firstLinePremier {
    display: none;
  }

  .firstSection {
    height: 120px;
  }

  .middleTxt, .middleTxtList, .middleTxt-2 {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .videoSub {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }

  .textOfBottomSection {
    width: 75vw;
  }
}

@media only screen and (max-width:390px) {
  .firstLine {
    font-size: 1rem;
  }

  .secondLine,
  .middleTxt {
    font-size: 1.2rem;
  }

  .necessaryMsg {
    text-align: center;
  }

  .whiteSection {
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  .middleTxt-2, .middleTxt {
    font-size: 1.1rem;
  }

  .mainMiddle {
    padding: 38px 30px 24px 30px;
  }
}

@media only screen and (max-width:375px) {
  .formSubTitle {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
  }
}